import React from 'react';
import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/body/Home';
import About from './components/body/About';
import BlogCategories from './components/body/BlogCategories';
import BlogCat from './components/body/blogCategory/BlogCategory';
import Blog from './components/body/blog/Blog';
import NoMatch from './components/body/NoMatch';
import NavigationBar from './components/NavigationBar';

function App() {
  let [toggleHamburger, setToggleHamburger] = useState(true);

  return (
    <React.Fragment>
      <NavigationBar
        changeToggleHamburger={(toggleHamburger) =>
          setToggleHamburger(toggleHamburger)
        }
      />
      {toggleHamburger && (
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/blog' component={BlogCategories} />
            <Route path='/about' component={About} />
            <Route exact path='/blog/:blogCategory' component={BlogCat} />
            <Route path='/blog/:blogCategory/:blogId' component={Blog} />
            <Route component={NoMatch} />
          </Switch>
        </Router>
      )}
    </React.Fragment>
  );
}

export default App;
