import React from 'react';
import BodyStyles from './BodyStyles';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BlogSelectionStyles = styled.div`
  text-align: center;
  a {
    color: #262626;
    text-decoration: none;
  }
`;

const BlogCategories = () => (
  <div>
    <Helmet>
      <title>Dennis Richard - My blog category selection page</title>
      <meta
        name='description'
        content="Hi I'm Dennis. This is my blog category selection page."
      />
    </Helmet>
    <BodyStyles>
      <BlogSelectionStyles>
        <div className='body para-styles'>
          <h1>Hi Everyone, welcome to my blog!</h1>
          <br />
          <p>
            The pandemic forced us to find things to do indoors, so I decided to
            write about some things. I've categorized my blog into two areas for
            now. Go ahead, knock yourself out!
          </p>
          <br />
          <Link to='/blog/recipes'>Food Recipes</Link>
          <br />
          <br />
          <Link to='/blog/life'>Life</Link>
          <br />
        </div>
      </BlogSelectionStyles>
    </BodyStyles>
  </div>
);

export default BlogCategories;
