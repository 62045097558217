const Life = {
  helmet: {
    title: 'Dennis Richard - This is Blog page about recipes',
    meta: {
      name: 'description',
      content:
        "Hi I'm Dennis. This is my recipes blog page. I have food some reciepes here, come check it out.",
    },
  },
  h1Tag: 'Welcome to my food blog!',
  topDescription: [
    'So you are foodie just like me. Thinking where to start? Dont worry just pick one and try it out. (Psst! Salmon is one of my favorite)',
  ],
  blogWithLink: [
    {
      link: '/blog/recipes/salmon',
      description: 'Salmon with root vegetable',
    },
    {
      link: '/blog/recipes/daal',
      description: "Dennis's world famous daal",
    },
    {
      link: '/blog',
      description: 'Go Back',
    },
  ],
  ingredientList: [''],
  midDescription: [''],
  instructions: [''],
  bottomDescription: ['', ''],
  finalPic: '',
};

export default Life;
