import styled from 'styled-components';

const BodyStyles = styled.div`
min-height: 90vh;
display: flex;
align-items: center;
justify-content: center;
font-size: 20px;
.body {
    font-family: OCR A Std, monospace;
    font-weight: bold;
    max-width: 1000px;
    padding 0 5vw;
    h1 {
        text-align: center;
        font-size: xx-large;
    }
}
.para-styles{
    p {
        text-align: justify;
        text-indent: 50px;
    }
}
`;

export default BodyStyles;
