import React from 'react';
import BodyStyles from '../BodyStyles';
import BlogStyles from './BlogsStyles';
import BlogHelmet from '../Helmet';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const getBlog = (match) => {
  const blogId = match.params.blogId
    .split('')
    .map((letter, index) =>
      index ? letter.toLowerCase() : letter.toUpperCase()
    )
    .join('');

  return require(`./blogs/${blogId}`).default;
};

const getPic = (finalPic) => {
  try {
    return require(`../../../assets/blogPics/${finalPic}.png`).default;
  } catch (err) {
    return null;
  }
};

const renderDescription = (blogObject, divClassName) => {
  return (
    <>
      {blogObject ? (
        <>
          <div className={divClassName + ' para-styles'}>
            {blogObject.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </div>
          <br />
        </>
      ) : null}
    </>
  );
};

const renderListItems = (blogObject, pValue) => {
  return (
    <>
      {blogObject ? (
        <>
          <p>{pValue}</p>
          <ul>
            {blogObject.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
          <br />
          <br />
        </>
      ) : null}
    </>
  );
};

const renderImage = (divClassName, image) => {
  return (
    <>
      {image ? (
        <>
          <img className={divClassName} src={image} alt={image} />
          <br />
          <br />
        </>
      ) : null}
    </>
  );
};

const Blog = ({ match }) => {
  const Blog = getBlog(match);
  const history = useHistory();
  const goBackHandle = () => {
    history.goBack();
  };

  return (
    <div>
      <BlogHelmet helmet={Blog.helmet} />
      <BodyStyles>
        <div className='body'>
          <h1>{Blog.h1Tag}</h1>
          <br />
          <BlogStyles>
            {renderDescription(Blog.topDescription, 'top-description')}
            {renderListItems(Blog.summary, 'Quick Note')}
            {renderListItems(Blog.ingredientList, 'Ingredients')}
            {renderDescription(Blog.midDescription, 'mid-description')}
            {renderListItems(Blog.instructions, 'Instructions')}
            {renderDescription(Blog.bottomDescription, 'bottom-description')}
            {renderImage('final-pic', getPic(Blog.finalPic))}
            {/* <Link to='/blog/recipes'>Go Top</Link>
            <br />
            <br /> */}
            <Link onClick={goBackHandle}>Go Back</Link>
            <br />
            <br />
          </BlogStyles>
        </div>
      </BodyStyles>
    </div>
  );
};

export default Blog;
