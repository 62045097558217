const Daal = {
  helmet: {
    title: 'Dennis Richard Blog - Daal Recipe',
    meta: {
      name: 'description',
      content: "Hi I'm Dennis, this is my blog. Here is a recipe for my daal.",
    },
  },
  h1Tag: "Dennis's world famous daal reciepe.",
  topDescription: [
    'If you are a busy professional like me you definely want to meal prep for the week. This is one of the easiest recipe that you can make over the weekend and work your way through it during the week.',
    "Legumes are essential and provides excellent nutrition, it's full of protien, fiber and carbs(Yeah that's right, but don't worry its exactly the right amount you need); and are very healthy grain option.",
    'Couple of my close friends have really hyped this recipe up(shout out to Heta & Kaushal), so I guess you find out for yourselves.',
  ],
  summary: ['Prep : 10 mins', 'Cook: 45 min', 'Total: 55 min', 'Serves: 6'],
  ingredientList: [
    '2 cups of masoor daal',
    '1 tea spoon red chili powder',
    '1/2 tea spoon turmeric',
    '1/2 tea spoon salt',
    '1/2 tea spoon mustard seeds',
    '1/2 tea spoon cumin',
    '1/2 medium onion',
    '4 indian chillies red or green',
    '1 table sppon cilantro(optional)',
    '3 cloves garlic(optional)',
    '15 curry leaves(optional)',
    '2 table spoon cooking oil',
  ],
  midDescription: [
    "Okay you got the items. Let the chef inside you take over you, let's get cooking!",
  ],
  instructions: [
    'Wash the lentil in cold water, I use a strainer, I found it easy this way.',
    'Set the lentils in a cooking pot.',
    'Add 5 cups of water and turn on the heat up high',
    'Add chili powder, turmeric, salt, gently stir the pot with a cooking spoon.',
    'Once you see it boil, set a cooking spoon in the pot, this way thr daal does not overflow from the cooking pot, my friend Heta though me this trick.',
    'While it cooks it redueces, so add water to keep the thickness like a runny sauce',
    'Finely chop onion and 2 chillies and cilantro and garlic(if you have it)',
    'The remaining 2 chillies, jullian it and save it for garnish ',
    'I like the daal to be creamy and smashed, so I boil it until it thickens a bit which is almost like 45 - 55min',
    'Add water accordingly if it does thickens a lot',
    'When it is close to being done, it time for tadka(finishing stage for this reciepe)',
    "Heat cooking oil in a small tadka pan on high, if you don't have one you can use a regular small sauce pan",
    'Once the oil is hot turn the heat to medium and add mustard seeds and cumin',
    'Wait until the pop, after this is done add onions',
    'Cook and stir a little until you see the onions sweat for like 5 min, add the chillies and curry leaves and garlic(if you have it)',
    'Cook for 5 min and stir and cook for another 5 min.',
    'Once tadka is done, add it to the daal pot',
    'Garnish with Cilantro and serve',
  ],
  bottomDescription: [
    'Drum roll please, (fast paced) da-ra da-ra da-ra da-ra da-ra da-ra da-ra da-ra da-ra da-ra da-ra  ba-dum-tss!',
  ],
  finalPic: 'finalDaal',
};

export default Daal;
