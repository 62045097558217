import styled from 'styled-components';

const BlogStyles = styled.div`
  text-align: center;
  
  ul {
    display: inline-block;
    text-align: left;
  }

  .final-pic {
    width: 90%;
    padding: 20px 10px;
  }

  a {
    color: #262626;
    text-decoration: none;
  }
`;

export default BlogStyles;
