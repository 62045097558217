const Lawn = {
  helmet: {
    title: 'Dennis Richard - Lawn Blog',
    meta: {
      name: 'description',
      content:
        "Hi I'm Dennis, this is my blog . Here is a short blog about my lawn experience so far. I do talk about how to water the lawn and how to treat yellow patches on your lawn.",
    },
  },
  h1Tag: 'My lawn experience',
  topDescription: [
    'We had just moved into our new home in Feb 2021 and we were in the process of settling in. Given that I have a one year old and it did take longer to settle in.',
    "Amidst of all this we did recieve a lot of mails welcoming to us to the neighborhood and few businesses reaching out to us. I did ignore most of it as it were coupons to things we din't want, like curtains and furniture etc. Among the mails, we also did notice few landscapers reaching to us. I considered to give landscaping a shot myself rather than hiring landscapers.",
  ],
  midDescription: [
    'In April, the weather started to get warm up and in May I started to notice yellow patches on my lawn. My wife did notice it as well and she mentioned it to me. My friend suggested that I water the lawn so I looked up online and found out that we kinda need to do this for like 20-30 min in mornings before 10am for about 3 times a week with a regular hose and sprinkler.',
    'And there I was trying to figure out how to water the lawn in my front and back yard and also on the sides of the home. So I started to scout out to see what I had(The previous home owners were very generous to leave us a bunch of lawn and garden equipment). There was some storage in the side of my backyard. When I opened it I notice it had two ossilating sprinklers, two rotating sprinklers, some pipes, pots, soil fertilizer, and two watering cans, then I went into garage by the front lawn, there was a faucet in the garage and pipe attached and rolled up at the side.',
    "I did extend the pipe from garage and started watering the sides for a good 15-20min ish covering all the lawn and bushes and shrubs, then I also covered the areas that the sprinkler wouldn't cover in the front and back lawn. This process took about an hour and after that, I just plugged in the ossilating sprinklers for both front and back lawn and let it run for a good 20-30min. And I did feel I achieved my task for that day. And So I did this like 3 times a week and I did see some imporvements and felt good.",
    "Now I started to notice some weeds grow by and was wondering how I can get rid of them, lukily the owners did leave us a bottle of weed be gone but it din't have the connector to the hose. There is a hardware store by my home and I went there looking for the connector and I did run into this youg gentleman Jason who helped me out; While I was there I did mention about my lawn turning yellow to him. He did suggest that it could be fungus or worms causing this and luckily it was neither but just dead grass. He did recommend planting new grass seeds in September as I may not be successfull in growing them in the summer heat.",
    "He did suggest to add lawn fertilizer so that the yellow patches may go away which by the way can also go with the weed be gone, so I added the fertilizer to the lawn. Saw some results but not 100% but still some progress. Since I just added the fertilzer, the next time I'll have to add it again is in mid season and the by the end of it.",
  ],
  bottomDescription: [
    'I guess now my goal is now to maintain the lawn by adding fertilizer and also watering them regularly and then kill the weeds using weed be gone and also by uprooting them, sometimes I do use the weed wacker on them; And then slowly by September rake out the dead grass and replant new ones and see how that goes. Now that I know on how to go about these things puts me in a better spot. Yeah it was definitely overwhelming in figuring this at first with all the new things that I had to tackle but now I know better.',
  ],
  finalPic: 'lawn',
};

export default Lawn;
