const Life = {
  helmet: {
    title: 'Dennis Richard - My Blog page about life experiences',
    meta: {
      name: 'description',
      content:
        "Hi I'm Dennis. This is my blog page about my life. I have some life experiences to share.",
    },
  },
  h1Tag: 'Welcome to my blog page about life',
  topDescription: [
    'Life has put me through differnt spots at different times and this is about sharing those expeiences.',
  ],
  blogWithLink: [
    {
      link: '/blog/life/lawn',
      description: 'My lawn experience',
    },
    {
      link: '/blog/life/snake',
      description: 'How did I use a snake?',
    },
    {
      link: '/blog',
      description: 'Go Back',
    },
  ],
  ingredientList: [''],
  midDescription: [''],
  instructions: [''],
  bottomDescription: ['', ''],
  finalPic: '',
};

export default Life;
