import React from 'react';
import styled from 'styled-components';
import BodyStyles from './BodyStyles';
import Helmet from 'react-helmet';

const Styles = styled.div`
  .outter-content {
    text-align: center;
  }
`;

const Home = () => (
  <div>
    <Helmet>
      <title>Dennis Richard Blog</title>
      <meta
        name='description'
        content="Hi I'm Dennis Richard; Welcome to my Blog. I currently blog about food receipes and my life experiences."
      />
      {/*TODO: add products life and tech to blog categories in future*/}
    </Helmet>
    <BodyStyles>
      <Styles>
        <div className='body'>
          <h1>Hi, I'm Dennis</h1>
          <h2>Welcome to my blog</h2>
        </div>
      </Styles>
    </BodyStyles>
  </div>
);

export default Home;
