import React from 'react';
import BodyStyles from './BodyStyles';
import Helmet from 'react-helmet';

const About = () => (
  <div>
    <Helmet>
      <title>Dennis Richard - Blog - About me page</title>
      <meta
        name='description'
        content="Hi I'm Dennis, this is a short description about my blog."
      />
    </Helmet>
    <BodyStyles>
      <div className='body para-styles'>
        <h1>Story so far...</h1>
        <br />
        <p>
          Hi I'm Dennis and this is my blog. It is unfortunate to see the world
          suffer from covid. And honestly it caught us off guard and it's
          impacts were devastating. I lived in queens when we had our first
          outbreak and it was scary.
        </p>
        <br />
        <p>
          All of our brilliant scientists are focussed on developing the
          vaccine. We definity need to keep researching and developing newer
          vaccines for the newly mutated strains. With all this going on around
          us, while a lot of us are focusing on physical well being, it has been
          proven time and time again that we should not neglect our mental
          health or our financial health.
        </p>
        <br />
        <p>
          People are stuck indoors and with little to no social interaction,
          this pandemic is taking a toll on our overall mental health. Although
          there can be improvement but I do try to keep and stay positive as
          much as possible. I am not a writer in anyway. All this is very new to
          me. This blog is my attempt to share some of my positive attitude with
          the world. Maybe reading this will help you take some stress off. So,
          welcome to my blog where I write about simple things like foods I cook
          often, some of my life experiences and possibily some tech stuff in
          the future.
        </p>
      </div>
    </BodyStyles>
  </div>
);

export default About;
