import React from 'react';
import Helmet from 'react-helmet';

const Helment = (props) => (
  <Helmet>
    <title>{props.helmet.title}</title>
    <meta name='description' content={props.helmet.meta.content} />
  </Helmet>
);

export default Helment;
