import React from 'react';
import BodyStyles from '../BodyStyles';
import BlogCatHelmet from '../Helmet';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BlogCatStyles = styled.div`
  text-align: center;
  a {
    color: #262626;
    text-decoration: none;
  }
`;

const getBlogCategory = (match) => {
  const blogCatId = match.params.blogCategory
    .split('')
    .map((letter, index) =>
      index ? letter.toLowerCase() : letter.toUpperCase()
    )
    .join('');

  return require(`./blogCategories/${blogCatId}`).default;
};

const BlogCategory = ({ match }) => {
  const Blog = getBlogCategory(match);

  return (
    <div>
      <BlogCatHelmet helmet={Blog.helmet} />
      <BodyStyles>
        <BlogCatStyles>
          <div className='body para-styles'>
            <h1>{Blog.h1Tag}</h1>
            <br />
            <div>
              <div>
                {Blog.topDescription.map((item, index) => {
                  return <p key={index}>{item}</p>;
                })}
              </div>
              <ul>
                {Blog.blogWithLink.map((item, index) => {
                  return (
                    <>
                      <br />
                      <br />
                      <Link to={item.link} key={index}>
                        {item.description}
                      </Link>
                    </>
                  );
                })}
              </ul>
              <br />
              <br />
            </div>
          </div>
        </BlogCatStyles>
      </BodyStyles>
    </div>
  );
};

export default BlogCategory;
