const Snake = {
  helmet: {
    title: 'Dennis Richard - Snake Blog',
    meta: {
      name: 'description',
      content:
        "Hi I'm Dennis, this is my blog. Here is a short blog about my experience in using a snake to unclog the shower drain.",
    },
  },
  h1Tag: 'How did I use a Snake?',
  topDescription: [
    'Wife and I moved into out new home and we were slowing settling in. Couple of months in, our friends came on over to see us. It was nice to have them over and we were all having a good time.',
    'One day I started to notice that the shower drain started to back up water and I though that it was just some block and pouring some water down might unclog this. We have one bathroom on the second floor and it shared with the other bedroom. Nancy did say that she notice the water backing up too. As a new home owner I was a bit concerned and obviously wanted to solve this problem.',
  ],
  midDescription: [
    "So there I was thinking what to do abou this. And I went on youtube and started to see videos on how people unclog drains. OMG! I saw some videos where the drains were so dirty that I can't even.",
    'Anyways, I was watching differnt people using a tool called snake more often. I dint even know that this tool existed. It is this long plastic bendable stick with tiny hooks attached to it. What they do is they take the snake and stick in the shower drain all the way in, and you you slowly move it in and out; And while do so you also rotate it. ',
    'So I go to the hardware store near my home and started to look for it. When I go to the aisle there are like 6 types of snakes; Luckily I did watch a bunch od videos and in some videos they use a smalll snak and in some they use a big, I did get the big one(about 18 inches) thining it can go longer and grab all the dirt and hair. I also did get like a couple of extras so that I have it just in case.',
    'I return home and unpack it. The first thing I did was to put on some latex gloves(dint want my hands to get dirty) and then I started using it the shower drain just like the videos, I slowly inserted the snake with the pointy end going down the shower drain first, the snake fully dint go in as the pipe was bent (I could tell). Then I slowly moved it back an forth and while doing this I also started rotating it. At first I felt it was doing nothing even though I did exactly on how they showed in the video. Then I did it again, but this time I fully inserted it and moved it just like before. After few min when I removed the snake it barly got any dirt with it but the clog was still there.',
    "In one of the videos I did see as guy use a very long snake with a handle and rotating lever(It was atleast 10 feet long). Off to the store I go again. I did see the they had 3 lenghts of this very long snake with handle; 10 feet, 15 feet, 25 feet. Obvisouly the longer it was, the expensive it got. I figured I'll get the 15 feet one and figure out the rest.",
    "Came back home and put on the latex gloves on; I unscrewed the show drain lid and kept the screws aside. This snake was rolled up, I pulled out some and let it in the drain and started to rotate the handle. After couple of stirrs I pulled it out to see if I got any dirt, but no luck. But do I give up, NO NEVER! I give it another shot this time I tried to push the snake further down and rotated it. And had not luck again. And this time I've comepletely clogged up the drain and I see some water backing up. I still keep trying and after trying like 6-7 times, I tell my wife that we probably need to call in the plumber. Just then my friend Rahul enters, and gives it a try, and inthe process the handle snaps.",
  ],
  bottomDescription: [
    "I went back to the store to see if they can replace it, they asked for the reciept which I din't have(I tough I must have left it at home). So I go back to home to find it and only to find out that Rahul some how manage to put the handle back together. Okay so one problem is solve and just 99 more to go.", 'Meanwhile Rahul keeps trying and using it, and at one point he was able to push in snake like 12 feet, I was definetly shocked to see it go inside this long. And he said he found something. After repeatedly twisting and pulling it back it got huge chunk of dirt with hair which looked black. It was disgusting and smelt like sewerage. We tripled plastic bagged it and took it out. Rahul thoroughly washed the snake and shower with vinegar. By the end of this we were so much relieved, huff!'
  ],
};

export default Snake;
