const Salmon = {
  helmet: {
    title: 'Dennis Richard - Blog - Salmon Recipe',
    meta: {
      name: 'description',
      content:
        "Hi I'm Dennis, this is my blog. Here is a recipe for my salmon.",
    },
  },
  h1Tag: 'Salmon with root vegetable reciepe',
  topDescription: [
    'The weekend is ahead and if you are wondering what you wanna make for your guests or just for yourself, then you must check this salmon recipe out.',
    "It's easy and quick, all you need is a baking tray/casarole dish to cook with and a few ingredients and you are ready to go.",
    "Sometimes I forget and miss out some ingredients, so make sure you have the ingredients ahead and you can also skip the optional ingredients if you don't have it, but it is fun when you make it all.",
  ],
  summary: ['Prep : 15 mins', 'Cook: 45 min', 'Total: 1 hr', 'Serves: 3 - 4'],
  ingredientList: [
    'About 1 pound Salmon Fillet',
    '1/2 stick of salted butter',
    '1/2 teaspooon salt',
    '1/2 teaspooon pepper',
    '1/2 teaspooon thyme',
    '1/2 teaspooon oregano',
    '1/2 smoked paprika(optional)',
    '2 carrots',
    '1/2 Lb potato',
    '1/4lb sweet potato(optional)',
    '1/4lb beets(optional)',
    '1/2 bunch asperagus',
  ],
  midDescription: [
    "Now that you have the items needed, it's time to have some fun. Alright, here we go!",
  ],
  instructions: [
    'You Non-stick cooking spray on the baking pan covering all areas; You can also butter the baking pan ',
    'Preheat over to 420°F',
    'Wash and peal the roots vegetable',
    'Cut up the root vegetable into cube like pieces about half inch and throw them in a mixing bowl',
    'Add the seasoning and mix well, save some salt and pepper for the fish and asperagus; Note: you can double the seasoning if you are adding the optional ingredients',
    'Place the vegetables in the baking tray',
    'Set oven timer to 45 minutes, if you are adding the optional ingredients, set timer to 55 minutes',
    'Put the baking tray in oven and bake',
    'Cut the fillet in small piece about 1.5 - 2 inches in width',
    'Season the fish with salt and pepper',
    'Wash and cut the base of the asperagus',
    'Season asperagus with salt and pepper',
    'When the timer hits 25 minutes, throw in the fish and asperagus in the baking tray',
    'Keep salmon skin on the baking tray for a crispy skin finish',
    'When the timer finishes, take out the baking tray and set it aside for couple of minutes and serve',
  ],
  bottomDescription: [
    "For the time you have been waiting all this time, now it's time to indulge. Plate the food and enjoy with your guests.",
  ],
  finalPic: 'finalSalmon',
};

export default Salmon;
