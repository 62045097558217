import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';
import { useState } from 'react';

const Styles = styled.div`
  .navbar-nav .nav-link {
    color: #737373;
    font-weight: bold;
    font-family: OCR A Std, monospace;
    font-size: 20px;
    margin: 0 !important;
    &:hover {
      color: black;
    }
  }
`;

const NavigationBar = (props) => {
  let isDesktopView = window.innerWidth > 991 ? true : false;
  let [toggleMobileMenu, setMobileMenuDisplay] = useState(isDesktopView);

  return (
    <Styles>
      <Navbar expand='lg'>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          onClick={() => {
            setMobileMenuDisplay(!toggleMobileMenu);
            props.changeToggleHamburger(toggleMobileMenu);
          }}
        />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto navbar-fixed-top'>
            <Nav.Item>
              <Nav.Link href='/'>Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='/blog'>Blog</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='/about'>About</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Styles>
  );
};

export default NavigationBar;
